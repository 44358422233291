import React, { useState } from 'react';
const backendURL =
  'https://us-central1-team-aef-firebase-spike-44d55.cloudfunctions.net/app';

const CreateUserForm = ({ setUser }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${backendURL}/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        return res.json();
      })
      .then((user) => {
        user = { ...user, username: user.id, password };
        setUser(user);
      })
      .catch(console.dir);
  };

  return (
    <form data-testid="CreateUserForm" onSubmit={handleSubmit}>
      <p>Create New User</p>
      <label>
        Username:{' '}
        <input
          name="username"
          value={username}
          onChange={({ target: { value } }) => setUsername(value)}
        />
      </label>
      <label>
        Password:{' '}
        <input
          name="password"
          value={password}
          onChange={({ target: { value } }) => setPassword(value)}
        />
      </label>
      <button>Create User</button>
    </form>
  );
};

export default CreateUserForm;
