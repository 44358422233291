import React, { useEffect } from 'react';
import MsgForm from './MsgForm';
import { db } from '../firebase';

import { useLocalStore, observer } from 'mobx-react';
import ChatStore from './models/Chat';

const Chat = observer(({ room, username }) => {
  // const [messages, setMessages] = useState([]);
  const store = useLocalStore(() => {
    return ChatStore.create({
      messages: [],
    });
  });
  // const addMessage = msg => {
  //   setMessages(messages => [...messages, msg]);
  // };

  useEffect(() => {
    db.collection(`chats`)
      .doc(room.id)
      .collection('messages')
      .orderBy('created_at', 'asc')
      .onSnapshot(querySnapshot => {
        querySnapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            store.addMessage({
              ...change.doc.data(),
              created_at: new Date(change.doc.data().created_at),
              id: change.doc.id,
            });
          }
          if (change.type === 'removed') {
            store.removeMessage(change.doc.id);
          }
        });
      });
  }, [room, store]);

  useEffect(() => {
    store.clearMessages();
  }, [store, username]);
  return (
    <div>
      <h2>{room.room}</h2>
      <ul id="messages">
        {store.messages.map(({ message, fileLocation }, i) => (
          <li key={message + i}>
            <p>{message}</p>
            {fileLocation &&
              !(
                fileLocation.includes('MOV') ||
                fileLocation.includes('mp4') ||
                fileLocation.includes('mp3')
              ) && <img src={fileLocation} alt={message} />}
            {fileLocation &&
              (fileLocation.includes('MOV') ||
                fileLocation.includes('mp4')) && (
                <video width="320" height="240" controls>
                  <source src={fileLocation} type="video/mp4" />
                </video>
              )}
            {fileLocation && fileLocation.includes('mp3') && (
              <audio width="320" height="240" controls>
                <source src={fileLocation} type="audio/mp3" />
              </audio>
            )}
          </li>
        ))}
      </ul>
      <MsgForm addMessage={store.addMessage} room={room} username={username} />
    </div>
  );
});

export default Chat;
