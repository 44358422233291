import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import * as firebaseTesting from '@firebase/testing';

const firebaseConfig = require('./credentials.json');

const ENV = process.env.NODE_ENV;

let testApp;

if (ENV === 'test') {
  testApp = firebaseTesting.initializeAdminApp({
    projectId: 'team-aef-firebase-spike',
  });
}
firebase.initializeApp(firebaseConfig);

export const db = ENV === 'test' ? testApp.firestore() : firebase.firestore();

export const storage = firebase.storage();

export default ENV === 'test' ? testApp : firebase;
