import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AudioRecorder from 'audio-recorder-polyfill';
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';
// if (window && !window.MediaRecorder) {
AudioRecorder.encoder = mpegEncoder;
AudioRecorder.prototype.mimeType = 'audio/mpeg';
window.MediaRecorder = AudioRecorder;
// }

ReactDOM.render(<App />, document.getElementById('root'));
