import React, { useState, useEffect } from 'react';
import './App.css';
import Chat from './components/Chat';
import firebase from './firebase';
import { db } from './firebase';
import CreateUserForm from './components/CreateUserForm';
import LoginForm from './components/LoginForm';

// const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:9090';
const backendURL =
  'https://us-central1-team-aef-firebase-spike-44d55.cloudfunctions.net/app';

function App() {
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    setRooms([]);
    if (user) {
      console.log('user with password: ', user, typeof user);
      fetch(`${backendURL}/login/${user.username}`, {
        method: 'POST',
        body: JSON.stringify({ password: user.password }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(res => res.json())
        .then(({ token }) => {
          console.log('token: ', token);
          setToken(token);
          return firebase.auth().signInWithCustomToken(token);
        })
        .then(() => {
          const currentUser = firebase.auth().currentUser;
          return db
            .collection('chats')
            .where('participants', 'array-contains', currentUser.uid)
            .get();
        })
        .then(chatDocs => {
          const newRooms = [];
          chatDocs.forEach(doc => {
            newRooms.push({ ...doc.data(), id: doc.id });
          });
          setRooms(newRooms);
        })
        .catch(console.dir);
    }
  }, [user]);

  const users = ['PC', 'PR', 'DR', 'Unauthorised'];

  if (!user || !token)
    return (
      <>
        <CreateUserForm setUser={setUser} />
        <LoginForm setUser={setUser} />
      </>
    );
  return (
    <div className="App">
      <header className="App-header">
        <h1>AEF Messenger</h1>
      </header>
      {/* <button onClick={() => window.open('http://www.google.com', '_system')}>
        Launch myapp
      </button> */}
      <button onClick={() => setToken(null) || setUser(null)}>Logout</button>
      {users.map(username => {
        return (
          <button
            onClick={() => setUser({ username, id: username })}
            key={username}
          >
            {username}
          </button>
        );
      })}
      {rooms.map(room => {
        return <Chat room={room} username={user.username} key={room.id} />;
      })}
    </div>
  );
}

export default App;
