import { types } from 'mobx-state-tree';
import Message from './Message';

const Chat = types
  .model('Chat', {
    messages: types.array(Message),
  })
  .actions(self => {
    return {
      addMessage(newMessage) {
        self.messages.push(newMessage);
      },
      removeMessage(messageId) {
        const messageToRemove = self.messages.find(
          message => message.id === messageId
        );
        if (messageToRemove) self.messages.remove(messageToRemove);
      },
      clearMessages() {
        self.messages = [];
      },
    };
  });

export default Chat;
