import React, { useState } from 'react';

const LoginForm = ({ setUser }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setUser({ username, password });
  };

  return (
    <form onSubmit={handleSubmit}>
      <p>Login as existing user</p>
      <label>
        Username:{' '}
        <input
          name="username"
          value={username}
          onChange={({ target: { value } }) => setUsername(value)}
        />
      </label>
      <label>
        Password:{' '}
        <input
          name="password"
          value={password}
          onChange={({ target: { value } }) => setPassword(value)}
        />
      </label>
      <button>Login!</button>
    </form>
  );
};

export default LoginForm;
