import { types } from 'mobx-state-tree';

const Message = types.model('Message', {
  created_at: types.Date,
  fileLocation: types.maybe(types.string),
  id: types.string,
  message: types.string,
  user: types.string,
});

export default Message;
