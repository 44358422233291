import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
// import MicRecorder from 'mic-recorder-to-mp3';
import { db, storage } from '../firebase';
import firebase from '../firebase';

// const recorder = new MicRecorder({ bitRate: 128 });

const messageSchema = yup.object().shape({
  input: yup.string().min(2, 'Must be at least 2 characters!').required(),
});

const MsgForm = ({ room, username }) => {
  const [file, setFile] = useState(null);
  const [recorder, setRecorder] = useState({});

  const handleSubmit = ({ input }, actions) => {
    db.collection('chats')
      .doc(room.id)
      .collection('messages')
      .add({
        message: input,
        created_at: firebase.firestore.Timestamp.fromDate(new Date()),
        user: username,
      });
    actions.resetForm();
  };

  const handleFileChange = ({ target }) => {
    const { files } = target;
    console.log(files[0]);
    setFile(files[0]);
  };

  const handleFileUpload = event => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('file', file);

    // fetch('httpr://localhost:9090/convert', {
    //   method: 'POST',
    //   body: formData,
    // })
    //   .then(body => body.blob())
    //   .then(blob => {
    //     const mp4File = new File([blob], `${file.name.slice(0, -4)}.mp4`);

    const storageRef = storage.ref();
    // const imagesRef = storageRef.child('images');
    const fileRef = storageRef.child(`images/${file.name}`);

    fileRef
      .put(file)
      .then(snapshot => {
        return storageRef.child(`images/${file.name}`).getDownloadURL();
      })
      .then(url => {
        return db
          .collection('chats')
          .doc(room.id)
          .collection('messages')
          .add({
            message: 'this is a file upload!',
            created_at: firebase.firestore.Timestamp.fromDate(new Date()),
            user: username,
            fileLocation: url,
          });
      });
    // });
    setFile(null);
  };

  const handleAudioUpload = () => {
    if (!file) return;
    const storageRef = storage.ref();
    // const imagesRef = storageRef.child('images');
    const fileRef = storageRef.child(`audio/${file.name}.mp3`);

    fileRef
      .put(file)
      .then(snapshot => {
        return storageRef.child(`audio/${file.name}.mp3`).getDownloadURL();
      })
      .then(url => {
        return db
          .collection('chats')
          .doc(room.id)
          .collection('messages')
          .add({
            message: 'this is an audio upload!',
            created_at: firebase.firestore.Timestamp.fromDate(new Date()),
            user: username,
            fileLocation: url,
          });
      });
    // });
    setFile(null);
  };
  console.log('recorder', recorder);
  return (
    <>
      <Formik
        initialValues={{ input: '' }}
        onSubmit={handleSubmit}
        validationSchema={messageSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Field name="input"></Field>
              <ErrorMessage name="input" />
              <button disabled={isSubmitting}>Send</button>
            </Form>
          );
        }}
      </Formik>
      {/* <Formik initialValues={{ file: '' }} onSubmit={handleFileUpload}>
        {({ isSubmitting }) => { */}
      {/* return ( */}
      <div>
        <button
          onClick={() => {
            navigator.mediaDevices
              .getUserMedia({ audio: true })
              .then(stream => {
                const recorder = new MediaRecorder(stream);
                recorder.addEventListener('start', e => {
                  console.log('Started recording', e.data);
                });
                recorder.addEventListener('stop', e => {
                  console.log('Stopped recording', e.data);
                });
                recorder.addEventListener('dataavailable', e => {
                  const blob = e.data;
                  console.log(blob);
                  const audioFile = new File([blob], `audio-${Date.now()}`, {
                    type: blob.type,
                  });
                  console.log(audioFile);
                  setFile(audioFile);
                });
                recorder.start();
                setRecorder(recorder);
              });
          }}
        >
          Start Recording
        </button>
        <button
          onClick={() => {
            // recorder
            //   .stop()
            //   .getMp3()
            //   .then(([buffer, blob]) => {
            //     const audioFile = new File(buffer, `audio-${Date.now()}`, {
            //       type: blob.type,
            //     });
            //     setFile(audioFile);
            //   });

            recorder.stop();

            recorder.stream.getTracks().forEach(i => i.stop());
          }}
        >
          Stop Recording
        </button>
        <button onClick={handleAudioUpload}>Upload Recording</button>
      </div>
      <form onSubmit={handleFileUpload}>
        <input
          type="file"
          name="file"
          onChange={handleFileChange}
          accept=".mp4, .mov, .MOV, video/quicktime, image/*"
        ></input>
        <button>Upload File</button>
      </form>
      {/* ); */}
      {/* }} */}
      {/* </Formik> */}
    </>
  );
};

export default MsgForm;
